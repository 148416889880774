<template>
  <div id="hlogin">
    <top :name="text"></top>
    <div>
      <input v-model="admin" type="text" placeholder="登录账号" />
      <input
        v-model="pwd"
        style="display: block"
        type="text"
        placeholder="登录密码"
      />
      <button @click="login" style="">登录</button>
    </div>
  </div>
</template>

<script>
import top from "../components/filltop.vue";

export default {
  components: {
    top,
  },
  data() {
    return {
      text: "登录",
      admin: "",
      pwd: "",
    };
  },
  methods: {
    login() {
      if ((this.admin ?? "") == "" || (this.pwd ?? "") == "") {
        this.$toast.fail("请完善信息");
        return false;
      }
      var load = this.$loading({
        lock: true,
        text: "登录中",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.axios({
        url: "https://wx.yaolvxing.com/user/login",
        method: "POST",
        data: "account=" + this.admin + "&password=" + this.pwd,
      })
        .then((res) => {
          load.close();
          if (res.data.code != 1 && res.data.msg != "登录成功") {
            this.$toast.fail(res.data.msg);
            return false;
          }
          localStorage.setItem("token", res.data.data.userinfo.token);
          this.$router.push({
            name: "xz_index",
          });
        })
        .catch((err) => {
          load.close();
          this.$toast.fail("接口报错");
        });
    },
  },
  mounted() {},
};
</script>


<style scoped>
div button,
input {
  /* padding: 0.2rem; */
  display: block;
  margin: 0.4rem auto;
}
</style>